.work-page-cont{
padding-top: 0px;
z-index:3;
width: 100%;
height: 100vh;
position: fixed;
overflow-y:  scroll;
padding-bottom: 50px;
background: #0E1117;

}

.dark{
background-color: #0f1116;
color: #fff;
}

.light{
background-color: #fff;
color: #252525;

}

.project-bullets li{
	font-size: 0.9em;
	display: flex;
	text-wrap:nowrap;
}

.light .project-bullets li{
	background-color: #f6f6f6;
}

video{
	width: 80%;
	border:5px solid #F2F2F2;
	overflow: hidden;
	padding: 0px;
}

.logos{
max-width: 500px;
}

.work-page-button{
color: #39FCF5;
padding: 0px;
top: 25px;
max-width: 150px;
display: block;
cursor: pointer;
font-size: 1.3em;
z-index: 20000000;
position: relative;
font-family: 'aileronsregular';
text-decoration: none;
overflow: visible;
margin:0 auto;

}

.work-page-button:before{
position: absolute;
left: 25px;
top:4px;
content: '';
width: 15px;
height: 15px;
background-color: #80EBFA;
box-shadow: 0px 0px 10px #80EBFA;
mix-blend-mode:hard-light;
}

.main-image{
width: 100%;
position: relative;
z-index: 1;
top: 0px;

}

.headers-work-page{
font-size: 12vw;
top: 30px;
mix-blend-mode: overlay;
position: absolute;
z-index: 2;
text-align: center;
}

.work-page-subcont{
width: 85%;
padding: 20px;
position: relative;
margin: 0 auto;
top:50px;

}

.work-page-project{
padding-top: 80px;
padding-bottom: 50px;
margin:0 auto;
width: 85%;

}


.project-header-cont{
position: relative;
font-size: 2em;
display: inline-block;
width: 45%;
vertical-align: top;
text-align: left;
margin: 0px;
border-right: 2px solid rgba(170,170,170,0.2);
padding: 20px;
}

.project-header{
	margin: 0px 0px 0px 0px;
	
}


.project-description-text{
	margin-top: 1em;
	font-size: 0.8em;
	font-family: Abel;
	font-style: italic;

}

.links-webs-projects{
	text-decoration: none;
	cursor: pointer;
	color: #3DD5E7;
}

.project-header-subcont{
	margin-top: 0px;
}

.large-description{
	font-family: Abel;
	width: 90%;
	text-align:left;
	font-size: 0.7em;
}

.project-bullets{
font-size: 2em;
display: inline-block;
width: 45%;
vertical-align: middle;
font-family: Abel;
text-align: center;
padding: 20px;
}

.project-bullets-header{
	text-align: left;
	font-family: 'aileronsregular';
}
.project-bullets ul{
	display: flex;
	flex-wrap: wrap;
	/* flex-direction: column; */
	column-gap: 10px;
	row-gap: 10px;
	font-size: 0.7em;
	padding: 0;
	margin-top: 1em;
}

.project-bullets li{
text-decoration: none;
display: block;
text-align: left;
width: 45%;
max-width: 200px;
font-family: Abel;
margin:0px;
line-height: 1em;
overflow: visible;
background-color: rgb(61 92 98 / 25%);
}




.work-page-galery {
width: 85%;
margin:0 auto;
padding-bottom: 50px;
}

.cont-img-galery{
	width: 100%;
	margin: 0 auto;
	padding: 50px;
	
}


.img-galery{

	width: 75%;
}

/*PHOTO///////////////////////////////////////////////////////////*/

.work-page-galery-photo {
 display: flex;
flex-wrap: wrap;
align-items: stretch;
width: 100%;
margin:0 auto;
padding-bottom: 5px;



}

.cont-img-galery-photo{
	
	width: 100%;
	
	

}

.column {
  flex: 33.3%;
  overflow: hidden;
}

/*
.work-page-galery-photo div:nth-of-type(1) {flex-grow: 1;}
.work-page-galery-photo div:nth-of-type(2) {flex-grow: 8;}
.work-page-galery-photo div:nth-of-type(3) {flex-grow: 1;}
.work-page-galery-photo div:nth-of-type(4) {flex-grow: 1;}
.work-page-galery-photo div:nth-of-type(5) {flex-grow: 20;}

*/

.cont_img_loader{
	position: relative;
	width: 100%;
	height: 100%;
	padding: 5px;
}
.img-galery-photo{
	
	width: 100%;
	height:  100%;
	
	opacity: 0.7;
	transition: all 0.5s;
	left: -200px;
	background-size: auto 120%;
	background-position: center;
}

.img-galery-photo:hover{
	opacity: 1;
}

.loaderImg{
position: absolute;
top: 0px;
height: 100%;
width: 100%;
background-color: #000;
z-index: 100000;
pointer-events: none;
padding: 50px;

}


.photoViewer{
	opacity: 0;
	position: fixed;
	top: 0px;
	height: 100vh;
	z-index: 60000000000000;
	background-color: #000;
}

#photoActive{
	top: 0px;
	position: fixed;
	z-index: 2000;
	background-color: #060606;
	text-align: center;
	width: 100%;
	height: 100vh;
	display: none;
	pointer-events: none;

}

.photoViewer img{
	padding-top: 40px;
	width: 90%;
	margin: 0 auto;
	
	
}

@media screen and (max-width: 1000px) {

.column {
    flex: 50%;
    max-width: 50%;
  }
}

@media screen and (max-width: 768px) {

.work-page-project{
width: 90%;
}

.work-page-subcont{
width: 90%;

}

.project-header-cont,.project-bullets{
	width: 50%;
	
	font-size: 1.8em;
	vertical-align: top;
	padding: 15px;
}

.project-bullets{


}
.work-page-galery {
width:90%;
margin:0 auto;
}
.cont-img-galery{
	width: 100%;
	margin: 0 auto;
	padding:0px;
	
}

.img-galery{
	margin: 0px 0px 15px 0px;
	width:95%;
}




}

@media screen and (max-width: 500px) {

	.project-header-cont,.project-bullets{
	width: 95%;
	
	font-size: 1.8em;
	vertical-align: top;
	padding: 10px;
	border: none;
}

.column {
    flex: 100%;
    max-width: 100%;
  }

}