.displayErrors input:invalid {
 border-bottom: solid 2px #FA8491;

 transition: all 0.3s;
}

form{
	display: block;
	width: 90%;
	margin-bottom: 50px;
	
}

.form-elements{
	position: relative;
	display: inline-block;
	
	top:0px;
}

.cont-inputs{
width: 70%;
}

.cont-button-next{
width: 30%;
top:45px;
text-align: center;
}

input{
	position: absolute;
	width: 95%;
	background-color:transparent!important; 
	font-size: 0.5em;
	color: #80EBFA; 
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: solid 2px #80EBFA;
	padding: 10px;
	margin: 0px;
	line-height: 20px;
	outline: none!important;
	opacity: 1;
	transition: all 0.5s;
	box-shadow: 0 0 0 30px #0E1117 inset;
}


input[type=text]:required:focus:valid:selected{
	 background-color:transparent!important; 
	
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #0E1117 inset !important;
    -webkit-text-fill-color: #80EBFA;
    font-size: 0.5em; 
}

.input-active{
opacity: 1;
visibility:visible;
left: 0px;
transition: all 0.5s;
}
.input-disabled{
visibility:hidden;
opacity: 0;
transition: all 0.5s;
left: -50px;
}





.button-next{
position: relative;
background-color: #80EBFA;
box-shadow: 0px 0px 10px #80EBFA;
color: #0e1117;
cursor: pointer;
z-index: 20000000;
font-family: 'aileronsregular';
text-decoration: none;
border:none;
font-size: 0.5em;
padding: 10px;
line-height: 20px;
margin: 0 auto;
width: 100%;
transition: all 0.3s;
}
.button-next:hover{
box-shadow: 0px 0px 20px #80EBFA;


}



.button-next-error{
position: relative;
background-color: #80EBFA;
box-shadow: 0px 0px 10px #80EBFA;
color: #0e1117;
cursor: pointer;
z-index: 20000000;
font-family: 'aileronsregular';
text-decoration: none;
border:none;
font-size: 0.5em;
padding: 10px;
line-height: 20px;
margin: 0 auto;
width: 100%;
opacity: 0.2;
transition: all 0.3s;
}



button{
	display: block;
	position: relative;
	width: 70%;
	background-color: #80EBFA;
	box-shadow: 0px 0px 10px #80EBFA;
	color: #0e1117;
	cursor: pointer;
	z-index: 20000000;
	font-family: 'aileronsregular';
	text-decoration: none;
	border:none;
	font-size: 0.5em;
	padding: 10px;
	
	line-height: 20px;
	transition: all 0.5s;
	opacity: 1;
	left: 0px;
}

.form-progress-cont{
	margin-top: 0px;
	
	display: block;
	width: 90%;
	top: 0px;
	position: relative;
}

.form-progress-stage{
	display: inline-block;
	width: 25%;
	font-size: 0.5em;
	font-family: "Abel";
	color:#3A6B69;
	left: 0px;
	top:8px;
	position: relative;
	text-align: justify;
}
.form-progress-active{
	color: #FDFCFC;
}

#stage-send{
	text-align: right;
	left: 15px;
}

.form-progress-selector{
	position: relative;
	display: block;
	width: 12px;
	height: 12px;
	background-color: #80EBFA;
	box-shadow: 0px 0px 10px #80EBFA;
	top: 20px;
	left: 0%;
}

.form-progress-cont:before{
	content: '';
	background-color: #496A6F;
	
	width: 100%;
	height: 2px;
	position: absolute;
	top: 25px;
}

.sending-loader{
	width: 0%;
	height: 2px;
	background-color: #80EBFA;
	box-shadow: 0px 0px 10px #80EBFA;
	
	transform-origin:"0% 0%";

}

#sending-confirm{
	position: relative;
	width: 100%;
	margin:0 auto;
	opacity: 0;
	z-index: 200000000;
	font-family: "Abel";
	color: #80EBFA;
	margin-top: -10px;
	top: -10px;
	pointer-events: none;
}

.contact-cont{
padding-top: 0px;
background-color: #0e1117;
width: 100%;
height: 100vh;
position: relative;
background-position:bottom;

}


.contact-super-subcont{
	position: relative;
	display: block;
	width: 100%;
	margin:0 auto;
	top: 0vh;
	
	
}

.contact-section1{
	position: relative;
	width: 100%;
	padding: 0px;
	margin: 0 auto;

	
}

.contact-header-cont{
display: inline-block;
width: 50%;
vertical-align: middle;
font-size:4em;
text-align: center;
background-image: url(../Media/img/contacto2.jpg);
height: 100vh;

}
.contact-header{
position: relative;
display: block;
color: #80EBFA;
top: 40vh;
text-shadow:0px 0px 5px #6AFFF6;

}

.contact-email-cont{
display: inline-block;
width: 50%;
vertical-align: middle;
font-size: 2.2em;
text-align: left;
padding: 100px;
	
}

.contact-email-header{
display: block;
font-size: 0.8em;
color: #fff;
margin-top: 50px;

}
.contact-email{
	position: relative;
font-family: "Abel";
text-decoration: none!important;
font-size: 0.8em;
}


.contact-email:hover.contact-email:before{
width: 100%;
}

.contact-email:before{
content: '';
height: 2px;
width: 0px;
position: absolute;
bottom: 0px;
background-color: #80EBFA;
box-shadow: 0px 0px 10px #80EBFA;
transition: all 0.3s;
}


.contact-email b{
color: #80EBFA;
font-weight: normal;
}

.contact-footer{
position: relative;
width: 90%;
margin:0 auto;
bottom: 0px;
	
}


.contact-redes{
display: inline-block;
width: 100%;
font-size: 0.6em;
vertical-align: top;
text-align: left;
float: right;
margin-top: 0px;

}

.contact-redes-header{
	color: #80EBFA;
}

.redes{

	display: inline-block;
	color: #80EBFA;
	margin-top: 10px;

}

.redes a{

	color: #DDFCFB;
	margin-right: 20px;
	text-decoration: none!important;
}
.redes a:hover{
	color: #80EBFA;
}


.contact-info{
	display: inline-block;
	position: relative;
	margin-top: 70px;
	width: 100%;
	

}

.contact-info-header{
font-size: 0.6em;
color: #80EBFA;

}

.contact-info ul{
padding: 0px;
margin-top: 10px;

}

.contact-info li{
	display: block;
	text-align: left;
	font-size: 0.45em;
	vertical-align: top;
	font-family: "Abel";
	color: #fff;


}

.copyright{
	position: absolute;
	font-size: 0.4em;
	bottom: 50px;
	color: #80EBFA;
}

@media screen and (max-width: 1020px) {



.contact-super-subcont{
	position: relative;
	display: block;
	width: 100%;
	margin:0 auto;
	top: 0vh;
	padding: 0px;
	
}
.contact-header-cont{
	position: relative;
	width: 100%;
	font-size:2.5em;
	max-height: 200px;
	background-size: 100%;
}

.contact-header{

color: #80EBFA;
top: 100px;


}
.contact-email-cont{
	margin-top:20px;
	width: 90%;
	padding: 20px;
	font-size: 2em;
	margin-bottom: 20px;
	
}

.contact-info{
	display: inline-block;
	position: relative;
	margin-top: 20px;
	width: 100%;
	

}

.copyright{

	position: absolute;
	font-size: 0.4em;
	bottom: 0px;
	color: #80EBFA;
	padding-bottom: 10px;
}

}


@media screen and(max-width: 500px){
	form{
	display: block;
	width: 100%;
	margin-bottom: 50px;

	
}
}