nav a::selection {
	background:none!important;
	color:#80EBFA;
}
/* Firefox */
.cont-nav::-moz-selection {
	background:none!important;
	color:#80EBFA;  
}

.cont-nav {
	position: fixed;
	justify-content: space-between;
	display: flex;
	z-index:2;
	margin: 0 auto;
	width: 100%;
	transition: all 0.5s;

}


.logo-nav-cont {
	padding: 5px;
}
.logo-nav{
	max-width: 100px;
	margin-top: 5px;
	padding: 5px;
}
/*
 animation: nav 0.2s ease-in-out forwards;
@keyframes nav {
	from { opacity: 0; transform: translate3d(0, -25px, 0) }
	to { opacity: 1; transform: translate3d(0, 0, 0) }
}
*/
nav {
	display: inline-block;
	position: relative;
	opacity: 1;
	height: fit-content;
	top: 50px;
	transition: all 0.5s;
}
.cont-nav-scroll nav {
	top: 15px;
}
.cont-nav-scroll {
	/* top: 10px; */
	/* height: 60px; */
	/* display: flex; */
	/* align-items: center; */
	/* padding: 5px 0px; */
	background-color: rgba(14,17,23,0.7);
	border-radius: 5px;
}
.nav-resp {
	display: none;
}
.nav-pointer {
	display: inline-block;
	position:absolute;
	overflow: visible;
	pointer-events: none;
}
.nav-lineas-cont {
	display: none;
}

.lang-cont {
	margin-top: 10px;
 display: inline-block;
 float: right;
 padding: 5px 20px;
}
.lang-links {
	display: inline;
	cursor: pointer;
}
.lang-links:hover {
	color: #81edfc;
	text-shadow:0px 0px 5px #6AFFF6;
}
nav a {
	color: #81edfc;
	margin: 0 2em;
	font-size: 1.2em;
	letter-spacing: 0.2em;
	position: relative;
	text-decoration: none !important;
	transition: all 0.5s;
}

nav a:hover {
	color: #81edfc;
	text-shadow:0px 0px 6px #6AFFF6;
}

@media screen and (max-width: 1025px) {
 .cont-nav {
		display: none;
		/* pointer-events: none; */
	}
	.nav-lineas-cont {
		width: 100%;
		cursor: pointer;
		display: flex;
		justify-content: flex-end;
		float: right;
		right: 5px;
		padding: 5px;
		z-index: 5;
		position: fixed;
	}
	.nav-resp {
		width:100%;
		z-index: 4;
		background-color: rgb( 14,17,23);
		position: fixed;
		padding-top:  5em;
		opacity: 0;
		height: 100vh;
		top: 0px;
		padding-top: 100px;
	}
	.nav-resp a {
		color: #81edfc;
		margin: 0em;
		font-size: 2.2em;
		line-height: 2em;
		display: block;
		text-align: center;
		text-decoration: none;
		z-index: 20000000;
		transition: all 0.5s;
	}
	.nav-resp a:hover {
		text-shadow:0px 0px 10px #6AFFF6;
	}
	.lang-cont{
		display: block;
		margin: 0 auto;
		float: none;
		padding: 50px;
		font-size: 2em;
	}
}




