.works-cont{
/*background-image: url(./img/experience.jpg); */
background-size: 100% 10%;

background-repeat: no-repeat;
overflow: hidden;
height: 100%;
background: rgb(14,17,23);
background: -moz-linear-gradient(90deg, rgba(14,17,23,1) 0%, rgba(18,46,51,1) 76%, rgba(17,54,56,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(14,17,23,1) 0%, rgba(18,46,51,1) 76%, rgba(17,54,56,1) 100%);
background: linear-gradient(90deg, rgba(14,17,23,1) 0%, rgba(18,46,51,1) 76%, rgba(17,54,56,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0e1117",endColorstr="#113638",GradientType=1);
	
}

.categoryName{
position: absolute;
font-size: 10em;
opacity: 0.5;
color: #14393D;
top:  50px;
left: 100px;
}

.coverWorks{
position: fixed;
top: 0px;
left: 0px;
width: 100%;

mix-blend-mode: overlay;
pointer-events: none;
	
}
.works-subcont{
position: relative;
width: 100%;
position: relative;
background-image: url(../Media/img/back-slider.svg);
background-size: 100%;
background-position:0% 50%;
text-align: left;

}	

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
 /* IE10+ CSS styles go here */
.coverWorks{
position: fixed;
top: 0px;
left: 0px;
width: 100%;
pointer-events: none;
opacity: 0.1;
}
}

/* Microsoft Edge Browser 12+ (All) - @supports method */
 
@supports (-ms-ime-align:auto) {
  .coverWorks{
position: fixed;
top: 0px;
left: 0px;
width: 100%;
pointer-events: none;
opacity: 0.1;
}
}
 
/* Microsoft Edge Browser 15+ - @supports method */
 
@supports (-ms-ime-align:auto)
and (-webkit-text-stroke:initial) {
  .coverWorks{
position: fixed;
top: 0px;
left: 0px;
width: 100%;
pointer-events: none;
opacity: 0.1;
}
}

.filter-cont{
position: relative;
display: inline-block;
color: #80EBFA;
font-family: 'aileronsregular';
font-size: 1em;
background-color: rgb(30,150,152,0.1);
padding-right: 20px;
top:0;
height: 100vh;
}

.filter-subcont{
position: relative;
top:35vh;
text-align: left;
margin: 0 auto;
}


.filter-li{
text-decoration: none;
display: block;
cursor: pointer;
line-height: 40px;
padding-left: 20px;
letter-spacing: 0.2em;

}

.filter-li::before{
content: "";

width: 1px;
height: 20px;
vertical-align: middle;
background-color: #44868F;
display: inline-block;
position: relative;
top: -3px;
margin: 0px 10px 0px 0px;
cursor: pointer;
transition: all 0.2s;

}

.filter-li-active{
color:#fff;
}


.filter-li-active::before{
	content: "";
position: relative;
width: 5px;
height: 20px;
vertical-align: middle;
background-color: #80EBFA;
box-shadow: 0px 0px 5px #3EF7F5;
display: inline-block;

top: -3px;
margin: 0px 10px 0px 0px;
cursor: pointer;
transition: all 0.2s;
}



.sliders-cont{
position:absolute;
display: inline-block;
padding-top: 100px;
vertical-align: top;
height: 100vh;
width: 90%;

}

.slider-btn{
position: fixed;
color: #fff;
display: inline-block;
z-index: 5000000;
top: 60vh;


}

.slider-btn-prev{
left: 20%;
}
.slider-btn-next{
	right: 20%;
}

.nav-Work-cont{
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	/*top:35vh;*/

}

.cont-li-works{
width: 50px;
height: 50px;
display: block;
position: relative;
top: 0px;
margin: 0px 0px 50px 30px;
cursor: pointer;
}

.work-nav-li{
width: 30px;
height: 1px;
background-color: #80EBFA;
display: block;
transition: all 0.2s;
margin:0 auto;

}

.cont-li-works:hover .work-nav-li{
	
height: 5px;
}

.work-nav-li-active{
position: relative;
display: block;
width: 10px;
height: 10px;
box-shadow: 0px 0px 5px #3EF7F5;

text-align: center;
}


.cont-trans{
position: absolute;
top:0px;
	
}


.cont-miniaturas{
position: absolute;
width: 100%;
text-align: center;

}



/*Component work//////////////////////////////////////////////*/
.works-mini-container{
position:fixed;
font-family: 'Abel', sans-serif;
margin:100px ;
height: auto;
/*cursor: url('../Media/img/cursor_scroll.svg'), auto;*/


}

.cont-desc-mini{
	position: relative;
	display: inline-block;
	width:30%;
	vertical-align: middle;
	text-align: center;
	margin: 0 auto;
	left: 30px;

}

.mini-header{
	font-size: 2.4em;
	color: #fff;
	font-family: 'aileronsregular';
	z-index: 100000;
	width: 100%;
	text-align: left;
	display: block;
	margin-left: -8px;
}

.button-project{
	background-color: #80EBFA;
	box-shadow: 0px 0px 10px #80EBFA;
	color: #0e1117;
	padding: 20px;
	max-width: 200px;
	display: block;
	cursor: pointer;
	z-index: 20000000;
	margin-top: 15px;
	font-family: 'aileronsregular';
	text-decoration: none;
}

.mini-descrip{
color: #C9E8E8;
text-align: left;
}

.img-mini-cont{
position: relative;
width: 70%;
vertical-align: middle;
display: inline-block;
z-index:101;
}



.miniaturas{
	
box-shadow: 0px 10px 20px #020A09;
border-radius: 5px;
opacity:1;
width: 100%;
mix-blend-mode: multiply;
transition: all 0.5s;
}


.img-mini-back{
position: absolute;
opacity: 0.2;
top: 0px;
z-index:100;
width: 100%;

}


/*Component work///////////END///////////////////////////////////*/

.cont-expand{
overflow: scroll;
position:relative;
top: 0;
left: 0;
z-index: 5000000000;
width: 100%;
height: 100%;
background: rgb(14,17,23);
background: -moz-linear-gradient(90deg, rgba(14,17,23,1) 0%, rgba(18,46,51,1) 76%, rgba(17,54,56,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(14,17,23,1) 0%, rgba(18,46,51,1) 76%, rgba(17,54,56,1) 100%);
background: linear-gradient(90deg, rgba(14,17,23,1) 0%, rgba(18,46,51,1) 76%, rgba(17,54,56,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0e1117",endColorstr="#113638",GradientType=1);
}

.img-expand{
	
	width: 90%;
	
}

.closeExpand{
position: fixed;
background-color: #2BD9DF;
top: 50px;
left: 0px;
}

/* SCROLL BAR ////////////////////////////////////////////////////*/
.cont-expand::-webkit-scrollbar {
  width: 8px;
}
.cont-expand::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}
.cont-expand::-webkit-scrollbar-thumb {
  background-color: #0D1C1E;
  border-radius: 10px;
}
/* SCROLL BAR ///////////////-END-/////////////////////////////////////*/

/*
////////////////////////////////////////////Slider Version//////////////////////////////////////////////////////
@media screen and (max-width: 768px){

.works-cont{

padding-top: 50px;
}

.coverWorks{
	width: 100%;
	top: 100px;
	overflow: visible;
}

.filter-cont{
display: inline;
top:0px;
width: 100%;

	}

.filter-subcont{
top:0vh;
text-align: center;
margin: 0 auto;
}


.filter-li{
display: inline!important;
padding-left: 20px !important;
}




.coverWorks{
	position: fixed;
	top: 0px;
	left: 0px;
	width: 200%;
	height: 100%;
	mix-blend-mode: overlay;
	margin-left: -250px;
}


.sliders-cont{
position:relative;
display: inline-block;
width: 100%;
vertical-align: top;
left: 0px;
padding-top: 20px;


}	

.works-mini-container{
position:fixed;
font-family: 'Abel', sans-serif;
margin:0px auto;
height: auto;
margin-left: 0px;

}	

.cont-desc-mini{
	width: 100%;
	left: 0px;
	padding-bottom: 50px;


}

.mini-header,.mini-descrip{
display: block;
vertical-align: middle;
margin: 20px;
}

.button-project{
display: block;
vertical-align: middle;
max-width: 40%;
margin:0 auto;
}


.img-mini-cont{

width: 100%;

}

.miniaturas{
	
width: 200%;
margin-left: -250px;
height: 100%;


}

}
////////////////////////////////////////Slider Version///////////////////////////////////////////////////////////

*/

/*//////////////////////////////////////////Grid Version/////////////////////////////////////////////////////////*/

@media screen and (max-width: 1025px) {
	

.categoryName{
display: none;
}

.sliders-cont{
position:relative;
display: inline-block;
width: 90%;
vertical-align: top;
left: 0px;
top:100px;
padding: 0px;

}

.works-cont{
overflow: visible;
padding-top: 50px;


}

.filter-cont{
display: inline;
top:30px;
width: 100%;
font-size: 20px;

}

.filter-subcont{
position: relative;
top:0vh;
text-align: center;
margin: 0 auto;
}


.filter-li{
display: inline!important;
padding-left: 20px !important;
}

.nav-Work-cont{
	display: none;
}

/*Component work//////////////////////////////////////////////*/
.works-mini-container{
position:relative;
font-family: 'Abel', sans-serif;
margin: 10px;
left: 0px;

}

.cont-desc-mini{
display: inline;
width: 100%;
position: absolute;
top:80%;
z-index: 60000000;
background-color: rgb(0,0,0,0.3);
padding: 20px 0px 20px 0px;
left: 0px;

}

.img-mini-cont{

display: block;
width: 100%;
}

.mini-header{
	font-size: 1.2em;
	color: #fff;
	font-family: 'aileronsregular';
	z-index: 100000;
	left:0px;
	display: inline;
	margin:20px 0px 0px 0px;
	
}

.miniaturas{
	position: relative;
	box-shadow: 0px 10px 10px #051414;
	width: 100%;
	border-radius: 5px;
	z-index: 100;
	margin-bottom: 15px;

}



.button-project{
	position: relative;
	background-color: #80EBFA;
	box-shadow: 0px 0px 10px #80EBFA;
	color: #0e1117;
	padding: 10px;
	max-width: 200px;
	cursor: pointer;
	z-index: 80000000000;
	opacity: 0;
	display: inline;
	right:0px;
	text-align: right;

}





.mini-descrip{
	color: #C9E8E8;
	text-align: left;
	display: inline;
	margin:20px;
}

/*Component work///////////END///////////////////////////////////*/




}

@media screen and (max-width: 768px) {

	.works-cont{
		background: none;

	}
.cont-desc-mini{
top:69%;
background-color: transparent;
}
.mini-header,.mini-descrip {
	display: none;
}

.coverWorks{
display: none;
	
}

}
@media screen and (max-width: 477px) {
.sliders-cont{
position:relative;
display: inline-block;
width: 90%;
vertical-align: top;
left: 0px;
top:50px;
padding: 0px;

}


.filter-cont{
display: inline;
top:30px;
width: 100%;
font-size: 15px;

}

.filter-li{

padding-left: 2px !important;
}

.button-project{
	position: absolute;
	background-color: #80EBFA;
	box-shadow: 0px 0px 10px #80EBFA;
	color: #0e1117;
	padding: 10px;
	max-width: 200px;
	cursor: pointer;
	z-index: 80000000000;
	opacity: 0;
	display: block;
	right:35%;
	bottom: 150%;
	text-align: right;

}


}