.carga-img {
	display: none;
}
#scroll-container {
	height: 100vh;
	overflow-x: hidden;
}
.cont-loader {
	position: fixed;
	background-color: #0E1117;
	z-index: 900000000000;
	width: 100%;
	height: 100%;
	text-align: center;
	pointer-events: none;
	transition: 1s all;
}

.cont-loader-off {
	position: fixed;
	background-color: #0E1117;
	z-index: 900000000000;
	width: 100%;
	height: 100%;
	text-align: center;
	pointer-events: none;
	opacity: 0;
	transition: 1s all;
}

#loader-svg {
	position: relative;
	width: 146px;
	height: 146px;
	margin:0 auto;
	top: 35%;
}

@keyframes fillL {
		0% { fill:#80ebfa; }
		50% { fill:#0e1117; }
	100% { fill:#80ebfa; }
}

@keyframes fillR {
		0% { fill:#0e1117; }
	 50% { fill: #80ebfa;}
	100% { fill:#0e1117; }
}

@keyframes x {
		0% { x: 20; }
	 50% { x: 110; }
	 100% { x: 20;}
}

#rec-l, #rec-r {
	animation-duration: 1.2s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
	animation-direction: reverse;
}

#rec-l {
	animation-name: fillL;
}
 #rec-r	{
	animation-name: fillR;
}

#rec{
	animation-name: x;
	animation-direction: reverse;
	animation-duration: 1.2s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
}











