@font-face {
    font-family: 'aileronsregular';
    src: url('./fonts/ailerons-regular-webfont.woff2') format('woff2'),
         url('./fonts/ailerons-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'aileronlight';
  src: url('./fonts/Aileron-Light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'aileronbold';
  src: url('./fonts/Aileron-Bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*::selection {
    background:#16282B;
    color:#80EBFA;
    
}
/* Firefox */
*::-moz-selection {
    background:#16282B;
    color:#80EBFA;  
}

body {

  line-height: 1.2;
  margin: 0;
  padding: 0;
  background-color: #0E1117;
  color:#fff;
  font-family: 'aileronsregular';
  
}

@media screen and (max-width: 768px) {

body {
    font-size: 14px;
    overflow: visible;
  }
}

/* SCROLL BAR ////////////////////////////////////////////////////*/
*::-webkit-scrollbar {
  width: 8px;
}
*::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb {
  background-color: #0D1C1E;
  border-radius: 10px;
}
/* SCROLL BAR ///////////////-END-/////////////////////////////////////*/


.app {
  
  text-align: center;
  width: 100%;
  
}



#cursor{
  position: absolute;
  z-index: 2000000;

}
#cursor-follower{
  position: absolute;
  top: -10px;
  left: -10px;
  content: '';
  width: 30px;
  height: 30px;
  border:1px solid #EF235D;
  z-index: 1;
  pointer-events: none;
}

#transition{
  position: fixed;
  left: 0px;
  height: 100vh;
  pointer-events: none;
  z-index: 0;
  
}
#transition2{
  position: fixed;
  left: 0px;
  height: 100vh;
  pointer-events: none;
  z-index:2;
  
}



h2 {
  line-height: 1.4;
  margin-top: 1.5em;
}

.lorem{
	font-family: 'Abel', sans-serif;
	letter-spacing: 0;
	font-size: 0.3em;
	padding: 50px;
}

.view a {
  color: #fff;
  text-decoration: none;
}







