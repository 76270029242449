.experience-cont{
	display: flex;
	flex-direction: column;
	width: 100%;
	background-size: 100%;
	padding: 4em 4em;
	padding-bottom: 2em;
	background-repeat: no-repeat;
	color: #daedf0;
	background: #0e1117;
	background: -moz-linear-gradient(90deg, rgba(14,17,23,1) 0%, rgba(18,46,51,1) 76%, rgba(17,54,56,1) 100%);
	background: -webkit-linear-gradient(90deg, rgba(14,17,23,1) 0%, rgba(18,46,51,1) 76%, rgba(17,54,56,1) 100%);
	background: linear-gradient(90deg, rgb(25, 31, 43) 0%, rgba(18,46,51,1) 76%, rgba(17,54,56,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0e1117",endColorstr="#113638",GradientType=1);
}

.header-sections-experience{
font-size: 2.3em;
text-align: center;
padding: 25px 0px;
color: #fff;
position: relative;
margin: 1em auto;
}

.habilidades-cont{
display: flex;
justify-content: space-between;
flex-wrap: wrap;
width: 100%;
column-gap: 2em;
row-gap: 2em;
/* padding: 0 2em; */
/* margin:0 auto; */
background-image: url(../Media/img/back-slider.svg);
background-size: 100%;
background-position:0% 40%;
}

.habilidades-sub-cont{
display: flex;
flex-direction: column;
}
.puntuaje{
display: flex;
flex-direction: column;
vertical-align: top;
line-height: 30px;
margin-top: 20px;
font-family: "aileronlight";
}
.puntuaje-subconts{
display:flex;
align-items: center;
}


.titulos-prueba{
position: relative;
display: flex;
width: 115px;
padding: 1em 0px 0px 0px;
font-size: 15px;
line-height: 1em;
text-align: left;
}

.cont-puntos{
position: relative;
display:flex;
vertical-align: middle;
width: 200px;
padding: 4px 0px 0px 0px;
font-size: 15px;
}

.puntos{
position: absolute;
content: '';
height: 2px;
width: 0%;
background-color: #80EBFA;
box-shadow: 0px 0px 5px #3EF7F5;
}

.puntos-web div{
background-color: #80EBFA;
box-shadow: 0px 0px 5px #3EF7F5;

}
.puntos-design div{
background-color: #FA8491;
box-shadow: 0px 0px 5px #FA8491;
}

.puntos-audio div{
background-color: #FAD5BA;
box-shadow: 0px 0px 5px #FAD5BA;
}

.experiencia-sub-cont{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.experiencia-sub-conts{
	display: flex;
	flex-direction: column;
	width:45%;
	text-align: left;
	vertical-align: top;
	border-radius: 6px;
}

.hab-headers-cont{
	margin-top: 30px;
	margin-bottom: 30px;
	text-align: center;
}
.exp-headers-cont{
	display: flex;
	align-self: center;
	padding: 5px;
}
.exp-header-elements{
	display: flex;
	font-size: 1.8em;
	color: #80EBFA;
}

.marcador{
	position: relative;
	width: 60px;
}

.icos-experience{
	position: relative;
	width: 120px;
}

.exp-info-cont{
	display: flex;
	flex-direction: column;
	margin-top: 30px;
	margin-bottom: 50px;

}
.exp-info-info{
	width: 75%;
}

.exp-info-info p:first-child{
	font-size: 18px;
}



.exp-title span{
	opacity: 0.7;
	background-color: #1A4D52;
	transform: skew(-20deg);
	padding: 0 8px;
}

.exp-title span a{
	display: block;
	font-size: 0.6em;
	text-decoration: none;
	color: #87c6ce;
	transform: skew(20deg);
	font-style: italic;
}

.exp-info{
	position: relative;
	display: inline-block;
	font-size: 1em;
	vertical-align: top;
	text-align: left;
}
.interests{
	margin-left: 70px;
	width: 90%;
	position: relative;
	display: flex;
	flex-wrap: wrap;
}
.interests span{
	overflow: visible;
	font-size: 18px!important;
	background-color: #43cedb3d;
	color: #80EBFA;
	width: fit-content;
	white-space: nowrap;
	padding: 5px;
	border-radius: 2px;
	margin: 4px;
	cursor: pointer;
	transition: color 0.2s;
}
.interests span:hover{
	color: #ffffff;
}
.interests p:before{
	content: '';
	width: 5px;
	height: 5px;
	position: absolute;
	left: -10px;
	margin-top: 6px;
	background-color: #80EBFA;
	box-shadow:  0px 0px 5px #80EBFA;
	border-radius: 5px;

}

.exp-info-subconts{
	position: relative;
	display: flex;
}

.exp-info-fecha{
display: flex;
flex-wrap: wrap;
justify-content: flex-end;
align-content: flex-start;
width: 20%;
color: #80EBFA;
text-align: right;
margin-right: 5px;
line-height: 18px;
}

.exp-info-fecha-line{
	position: relative;
	display: inline-flex;
	width: 1px;
	content: "";
	background-color: #3EF7F5;
	margin: 0 10px;
	opacity: 0.4;
}

.exp-info-fecha-line::after{
	opacity: 1;
	position: absolute;
	width: 5px;
	height: 5px;
	top: 6.5px;
	left: -2px;
	content: "";
	background-color: #3EF7F5;
	border-radius: 100%;
}

.start{
	height: 24px;
}

.start::after{
	width: 0;
	height: 0px;
	left: -4.5px;
	top: 0;
	border-radius: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	background-color: transparent;
	border-bottom: 5px solid #3EF7F5;
}

.exp-info-info{
	margin-top: 0px;
	font-family: "aileronlight";
	margin-bottom: 5px;
	line-height: 20px;
	padding-bottom: 2em;

}
.exp-info-info b{
	color: #80EBFA;
}

.langss{
	/* max-width: 10%; */
	width: auto;
	margin-left: 25px;
	justify-content: center;
	margin-right: 0;
}


.exp-title{
	font-family: "aileronbold";
	display: flex;
	column-gap: 15px;
	margin-bottom: 0.5em;
}
.exp-title b{
	font-family: "aileronbold";
}

.marcador{
	top:2px;
}

.languages-icons{
	position: relative;
	width: 26px;
	/* top: -50%; */
}

.arrow-titles{
	height: 15px;
	margin:0 0.5em 0em 0.5em;
}

.resume-download-cont{
background-color: #80EBFA;
box-shadow: 0px 0px 5px #80EBFA;
color: #0e1117;
padding: 20px;
max-width: 240px;
cursor: pointer;
margin: 0 auto;
text-decoration: none;
position: relative;
text-align: center;
}
.download-text{
	position: relative;
	text-align: center;
}
.resume-download-subcont{
	position: absolute;
	opacity: 0;
	margin:0 auto;
	text-align: center;
	width: 80%;
	top:20px;
}
.resume-download-subcont a{
	text-decoration: none;
	padding: 5px;
	text-align: center!important;
	color: #0e1117;
	transition: all 0.3s;
	font-size: 1.2em;
	margin:5px;
}

.resume-download-subcont a:hover{
color: #80EBFA;
background-color:  #1A4D52;
}


@media screen and (max-width: 1020px) {
.experience-cont{
width: 100%;
background-size: 100%;
padding-top: 20px;
background-repeat: no-repeat;
background: rgb(14,17,23);
background: -moz-linear-gradient(90deg, rgba(14,17,23,1) 0%, rgba(18,46,51,1) 76%, rgba(17,54,56,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(14,17,23,1) 0%, rgba(18,46,51,1) 76%, rgba(17,54,56,1) 100%);
background: linear-gradient(90deg, rgba(14,17,23,1) 0%, rgba(18,46,51,1) 76%, rgba(17,54,56,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0e1117",endColorstr="#113638",GradientType=1);
}

.experiencia-sub-conts{
	width:100%;
	justify-content: flex-start;
}
.exp-info-fecha{
	width: 15%;
	color: #80EBFA;
	text-align: right;
	margin-right: 5px;
}
.exp-info-info{
	width: 75%;
}
.resume-download-cont{
	margin-top: 50px;
}

}

@media screen and (max-width: 800px) {

.experience-cont{
	padding: 0em 1em;
	padding-bottom: 2em;
}

.header-sections-experience{
	font-size: 1.8em;
}

.hab-headers-cont{
	text-align: center;
}

.habilidades-cont{
	display: flex;
	justify-content: center;
}

.puntuaje{
	display: inline-flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-between;
}

}

@media screen and (max-width: 665px) {
	.puntuaje{
		/* display: inline-flex;
		flex-wrap: wrap;
		flex-direction: row; */
		justify-content: center;
	}
}

@media screen and (max-width: 450px) {

.icos-experience{
	position: relative;
	width: 65px;
}

}