

.home-0-sub-cont{
position: relative;
/* top: 45vh; */
}

.logo-home{
	max-width:350px;
}

.home-sections.home-0{
	display: block;
	background-image: url(../Media/img/diego.jpg); 
	background-size: 100%;
	background-repeat: no-repeat;
}
.home-cont{
	position: relative;
	width: 100%;
	background-color: #0e1117;
}

.home-sections{
position: absolute;
display: flex;
overflow: hidden;
flex-wrap: wrap;
align-content: space-around;
width: 100%;
height: 100vh;
opacity: 0;
transition: all 0.75s;
transition-delay: 0.2s;
left: -100px;
}

.section-active{
z-index: 30000;
opacity: 1;
left:  0px;
}

.subsections-home-cont{
position: relative;
display: flex;
align-items: center;
justify-content: space-between;
flex-wrap: wrap;
width: 100%;
margin: 0;
/* top: 50%;
-ms-transform: translateY(-50%);
transform: translateY(-50%); */
padding: 0 120px;
overflow: visible;
}



.home-navigator{
vertical-align: middle;
margin: 0 auto !important;
padding: 200px;

}

.home-nav-li{
width: 20px;
height: 2px;
vertical-align: middle;
background-color: #80EBFA;
display: inline-block;
position: relative;
top: 0px;
margin: 0px 0px 30px 0px;
cursor: pointer;
transition: all 0.2s;

}

.home-nav-li-active{
width: 10px;
height: 6px;
box-shadow: 0px 0px 5px #3EF7F5;
}

.titulos-sections-home{
position: absolute;
vertical-align: middle;
width: 5%;
height: 100vh;
display: inline-block;
writing-mode: vertical-lr;
font-size: 1em;
background-color: rgb(30,150,152,0.08);
left:0px;
top:0px;
text-align: center;
z-index: 30011;
padding: 20px;
opacity: 0;



}

.titulo-home-cont{
vertical-align: middle;
margin: 0px 20px 30px 20px;

}

.subsections-home{
	display: flex;
	flex-direction: column;
vertical-align: middle;
width: 50%;
}


.imDiego{
	font-size: 3.5em;
	text-align: left;
	width: 100%;
}


h1{
color: #fff;
font-size: 2.5em;
text-align: left;
letter-spacing: 0.1em;
font-weight: normal;
width: 100%;
position: relative;
margin: 20px 0;
}


h1 span{
color:#81edfc;
}

.buttons{
position: fixed;
margin-left: 50%;
left: -25px;
z-index: 10000100;
cursor: pointer;
}


.button-up{
	top: 120px;
	opacity: 0;
}


.button-down{
	top:92vh;
	opacity: 1;
}



#textSvg{
	font-size: 1.5em;
}

.textos-home{
font-family: 'Abel';
font-size: 1.5em;
text-align: left;
}

.home-1{
background-image: url(../Media/img/diego_about.jpg); 
background-size: 100%;
background-repeat: no-repeat;


}

.home-1-r{

}

.home-2{
background-size: 70%;
background-image: url(../Media/img/formas-h2.svg);
background-repeat: no-repeat;
background-position: 120% -400px;


}

.subsections-home-2{

vertical-align:middle;
/* width: 30%; */


}

.first-subsection2{
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: flex-start;
	width: 40%;
}

.h-2-cont{
overflow: hidden;
}

.h-2{
font-size: 10em;
}

.button-hireMe{
background-color: #80EBFA;
box-shadow: 0px 0px 10px #80EBFA;
color: #0e1117;
padding: 20px;
max-width: 300px;
cursor: pointer;
margin: 0 auto;
transition: all 0.3s;

}
.button-hireMe:hover{
	box-shadow: 0px 0px 20px #80EBFA;
}

.subsections-home a{
	text-decoration: none;
	color: #0e1117;

}


.home-2-r img{
	padding-right: 15px;
}

.bullets-h2{
	display: block;
	text-align: left;
	
}
.home-2-r b{
	color: #80EBFA;
	font-weight: normal;
}

.bullets-h2{
font-size: 1.5em;
letter-spacing: 0.3em;
color: #fff;
margin-bottom: 10px;
display: block;
width: 100%;
margin-right: 5px;
padding:5px;
}

.bullets-text{
position: relative;
opacity:0.2;
transition: 0.5s all;
padding-left: 20px;
cursor: pointer;
z-index: 20;
overflow: hidden;
}
.bullets-text:hover{
	opacity:1;
}	

.bullets-text-active{
opacity: 1;
top:  0px;

}
.bullets-h2::before{
position: absolute;
content: '';
width: 5px;
height: 5px;
background-color: #80EBFA;
box-shadow: 0px 0px 5px #3EF7F5;
top: 15px;
left: -1px;
transition: 0.3s all;
	
}

.bullets-active::before{
position: absolute;
content: '';
width: 15px;
height: 15px;
background-color: #80EBFA;
box-shadow: 0px 0px 5px #3EF7F5;
top: 11px;
left: -5px;
transition: 0.3s all;
}


.home-2-3{
width: 60%;
position: relative;
}

.cont-especial{
position: absolute;
display: flex;
column-gap: 2em;
row-gap: 1em;
flex-wrap: wrap;
transition: 0.5s all;
opacity: 0;
font-family: "Abel";
text-align: left;
}

.cont-especial-active{
position: absolute;
/* vertical-align: middle; */
transition: 0.5s all;
opacity: 1;

}

/* .cont-especial:before{
position: absolute;
content: '';
width: 1px;
height: 0%;
background-color: #80EBFA;
box-shadow: 0px 0px 5px #3EF7F5;
top: 15px;
left: -22px;
transition: 1.3s all;
 transition-delay: 0.3s;
} */

/* .cont-especial-active:before{
position: absolute;
content: '';
width: 1px;
height: 90%;
background-color: #80EBFA;
box-shadow: 0px 0px 5px #3EF7F5;
top: 15px;
left: -22px;

} */



.especial-bullets{
font-size: 1.2em;
position: relative;
padding: 10px;
border-radius: 5px;
background-color: rgb(61 92 98 / 25%);

}

.especial-bullets::before{
position: absolute;
content: '';
width: 2px;
height: 0.8em;
background-color: #80EBFA;
box-shadow: 0px 0px 5px #3EF7F5;
top: 13px;
left: -1px;
transition: 0.3s all;
}

.back-especial-bullets{
position:fixed;
top:-400px;

right:  0px;
pointer-events: none;
z-index: -1;
}



.back-especial-bullets img{
	width: 100%;
}

.home-3{
	background-image: url(../Media/img/IMG_7785.jpg); 
background-size: 100%;
background-repeat: no-repeat;
	
}
.home-3-sub-cont{
	
}

.header-aboutme{
font-size: 3.5em;
text-align: center;
margin-bottom: 15px;
}
.text-aboutme{
width:100%;
text-align: justify;
font-family: "Abel";
font-size: 1.2em;
line-height: 1em;
}
.text-aboutme p{
	margin: 10px 0;
}

#fondo-section{
position: absolute;
top: 0;
left: 0;
width:100%;
}

@media screen and (max-width: 1200px) {

.home-sections.home-0{
background-size: 200%;
}

.home-1{
background-size: 130%;
}

}

@media screen and (max-width: 1025px) {


.logo-home{

max-width:300px;
}	

.buttons{
z-index: 1000099;
}

.button-up{
top: 50px;
}
.button-down{
bottom:20px;
}


.titulos-sections-home{
/* width: 15%; */
padding: 10px;

}

.titulo-home-cont{
vertical-align: middle;
margin: 0px 5px 30px 10px;

}

.home-sections.home-0{
background-size: 250%;
}

.home-1{
background-size: 230%;
background-position: top right;

        
}

.subsections-home-cont{
position: relative;
width: 100%;
top:0%;
-ms-transform: translateY(0%);
transform: translateY(0%);
box-sizing: border-box;
}

.subsections-home{

width: 90%;

}


h1{
color: #fff;
font-size: 1.8em;
text-align: left;
letter-spacing: 0em;
font-weight: normal;
width: 100%;
position: relative;
}


h1 span{
	
color:#81edfc;

}

.textos-home{
	font-size: 1.3em;
}

.imDiego{
font-size: 2.5em;
text-align: left;
}

/* .home-2{
display: flex;	
background-size: 100%;
background-repeat: no-repeat;
background-position: 120% 100px;
}
*/
.h-2{
font-size: 5em;
margin-bottom: 25px;
}

.subsections-home-2{
width: 100%;
}
/*

.bullets-h2{
font-size: 1.4em;
	
}
.home-2-r b{
color: #80EBFA;
font-weight: normal;
}

.bullets-h2::before{

width: 6px;
height: 6px;
left: -20px;

}

.bullets-active::before{

width: 12px;
height: 12px;
left: -22px;
}

.bullets-text{
margin:0 auto;


}


.cont-especial{
position:absolute;
top:410px;
vertical-align: middle;
transition: 0.5s all;
opacity: 0;
font-family: "Abel";
text-align: left;
margin:0 auto;

padding: 25px;
left: 20%;
margin-left: -50px;
}

.cont-especial-active{
position: absolute;
top:410px;
vertical-align: middle;
transition: 0.5s all;
opacity: 1;
padding: 25px;
left: 20%;
margin-left: -50px;
}

.cont-especial:before{

left: 0px;

}

.cont-especial-active:before{

left: 0px;

} */

/* 
.back-especial-bullets{
position:fixed;
top:0px;
right:  0px;
pointer-events: none;
z-index: -1;
}

.especial-bullets{
position: relative;
margin: 15px;
display: inline-block;
}

.especial-bullets::before{
position: absolute;
content: '';
width: 10px;
height: 10px;
background-color: #80EBFA;
box-shadow: 0px 0px 5px #3EF7F5;
top: 3px;
left: -13px;
transition: 0.3s all;
} */



.button-hireMe{
max-width: 300px;
cursor: pointer;
margin: 0 auto;
margin-top: 50px;

}




.logo-home{

max-width:250px;
}

.home-3{
background-size: 170%;
background-position: top left;
}
.home-3-sub-cont{
width: 95%;
overflow: visible;
}

.header-aboutme{
font-size: 2.5em;
text-align: left;

}
.text-aboutme{
text-align: justify;
font-family: "Abel";
font-size: 1.2em;
}

.especial-bullets{
	font-size: 1em;
}

}


@media screen and (max-width: 600px) {
	.home-sections{
		align-content: flex-start;
		padding-top: 130px;
	}
.home-sections.home-0{
	justify-items: center;
	align-content: center;
	padding-top: 0px;
	background-size: 320%;
}

.home-1{
	background-image: url(../Media/img/diego_about_mobile.jpg); 
	background-size: 110%;
	background-repeat: no-repeat;
}

.subsections-home-cont{
	/* padding: 0px; */
	width: 100%;
	padding: 0 30px;
	justify-content: center;
}

.subsections-home {
	overflow: visible;
	width: 95%;
}

.titulos-sections-home{
display: none;
}

.home-3{
background-image: url(../Media/img/IMG_7785_mobile.jpg); 
background-size: 100%;
background-repeat: no-repeat;
}

.header-aboutme{
font-size: 1.9em;
padding-bottom: 15px;
}
}